:root {
  font-size: 60%;

  --color-background: #F0F0F7;
  --color-primary-lighter: #457099;
  --color-primary-light: #5c96cc;
  --color-primary: #345574;
  --color-primary-dark: #263e56;
  --color-primary-darker: #6842C2;
  --color-secondary: #ff914d;
  --color-secondary-dark: #f4833d;
  --color-title-in-primary: #FFFFFF;
  --color-text-in-primary: #fff;
  --color-text-title: #32264D;
  --color-text-complement: #9C98A6;
  --color-text-base: #6A6180;
  --color-line-in-white: #E6E6F0;
  --color-input-background: #F8F8FC;
  --color-button-text: #FFFFFF;
  --color-box-base: #ffffff;
  --color-box-footer: #FAFAFC;
  --color-green-whatsapp: #04d361;
  --color-green-whatsapp-secondary: #0edd6b;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  height: 100vh;
}

body {
  background: var(--color-background);
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

body,
input,
button,
textarea {
  font: 500 1.6rem Poppins;
  color: var(--color-text-base);
}

.container {
  width: 90vw;
  max-width: 700px;
}

@media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}