.textarea-block {
  position: relative;
}

.textarea-block + .textarea-block {
  margin-top: 1.4rem;
}

.textarea-block label {
  font-size: 1.4rem;
}

.textarea-block textarea {
  width: 100%;
  height: 16rem;
  min-height: 10rem;
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  background: var(--color-input-background);
  border: 1px solid var(--color-line-in-white);
  outline: 0;
  resize: vertical;
  padding: 1.2rem 1.6rem;
  font: 1.6rem Archivo;
}

.textarea-block:focus-within::after {
 width: 0;
 height: 2px;
 content: '';
 background: var(--color-primary-light);
 position: absolute;
 left: 1.6rem;
 right: 1.6rem;
 bottom: 7px;
 opacity: 0;
 animation: line .4s forwards;
}

@keyframes line {
  100% {
    width: calc(100% - 3.2rem);
    opacity: 1;
  }
}